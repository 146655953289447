import { LanguageRegions } from 'domains/languageRegions'
import { User, UserOperatorRoles, UserRoles } from 'domains/user'
import { UserData } from 'domains/user/UserData'
import { EmptyAPIResponse } from 'types/APIResponses'
import { Nullable } from 'types/utility'

import { protectedApi } from './requester'

export const getUsersList = (user: string) => {
  return protectedApi.get<User[]>(`/users?search=${user}`).then((response) => response.data)
}

export const getUser = (user: string) => {
  return protectedApi.get<UserData>(`/user/${user}`).then((response) => response.data)
}

interface UpdateUserOptions {
  username: string
  userRole?: Nullable<UserRoles>
  userOperatorRole?: Nullable<UserOperatorRoles>
  failedLoginCount?: number
  syncToCognito?: boolean
  enableInCognito?: boolean
  deleteFromCognito?: boolean
  workflowTypes?: string
}

export const updateUser = ({ username, ...params }: UpdateUserOptions) => {
  return protectedApi.put<EmptyAPIResponse>(`/user/${username}`, {}, { params })
}

interface CreateAdminParams {
  username: string
  firstName: string
  lastName: string
  email: string
}

export const createAdmin = (params: CreateAdminParams) => {
  return protectedApi.post<EmptyAPIResponse>('/user/admin', params)
}

interface LanguageRegionOptions {
  username: string
  languageRegion?: Nullable<LanguageRegions>
}

export const updateLanguageRegion = ({
  username,
  ...languageRegion
}: LanguageRegionOptions): EmptyAPIResponse => {
  return protectedApi.put(`/user/${username}`, languageRegion)
}
